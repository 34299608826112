import "core-js/modules/es.array.push.js";
import { reactive, toRefs, nextTick, defineAsyncComponent, defineComponent, computed } from "vue";
import httpApi from "@/api/httpApi";
import { bdEncrypt } from '@/utils/bdGPS';
import dayjs from 'dayjs';
import { isNullOrUnDef } from '@/utils/is';
export default defineComponent({
  name: "BMapMarkerClusterer",
  props: {
    isCustomizeStyle: {
      type: Boolean,
      default: false
    },
    isEmbed: {
      type: Boolean,
      default: false
    }
  },
  components: {
    InfoContent: defineAsyncComponent(() => import('@/views/live/LiveInfoWindow.vue')),
    BSJMap: defineAsyncComponent(() => import("@/components/BSJMap"))
  },
  setup() {
    const refData = reactive({
      refMap: null,
      school: null,
      schoolId: null,
      MarkerClusterer: null,
      infoWindow: null,
      refInfo: null,
      active: null,
      points: [],
      currentCardId: null,
      mapClusterer: null,
      infoMarker: null
    });
    // 位置点集合
    let mapClustererMarkers = [];
    //点击聚合点
    const handleMarkerClick = student => {
      const [, refMap] = getMap();
      //if (refData.currentCardId !== student.objectid) {
      // 切换标记点, 并打开窗口
      refData.currentCardId = student.objectid;
      refData.active = student;
      //  return;
      //}
      // 标记点已经打开, 则关闭
      if (refMap.isOpenWindow()) {
        return closeInfoWindow();
      }
      openInfoWindow();
      //refData.active = info;
    };
    // 打开窗体
    function openInfoWindow() {
      const [map, refMap] = getMap();
      const current = refData.active;
      if (isNullOrUnDef(current)) {
        return;
      }
      const point = bdEncrypt([current.lon, current.lat]);
      map.setCenter([point.lon, point.lat]);
      refMap.openInfoWindow({
        lon: point.lon,
        lat: point.lat
      });
    }
    // 关闭窗口
    function closeInfoWindow() {
      const [map, refMap] = getMap();
      refMap.closeInfoWindow();
    }
    // 信息窗口内容
    const infoContent = computed(() => {
      const current = refData.active;
      if (isNullOrUnDef(current)) return null;
      return {
        avatarImg: require('@/assets/images/avatar.png'),
        name: current.objectname,
        gender: ['熄火', '启动', ''][current.acc],
        gnss: current.gnss,
        gsm: current.gsm,
        online: current.onlineStatus,
        posType: current.posType,
        deviceType: current.deviceType,
        mdtid: current.mdtid,
        // imei: current.deviceType === 1 ? current.imei : '',
        remainBattery: parseFloat(current.electricity),
        voltage: current.voltage / 10 + 'V',
        posTime: dayjs(current.recvTime).format('YYYY-MM-DD HH:mm:ss'),
        gpsTime: dayjs(current.gpsTime).format('YYYY-MM-DD HH:mm:ss'),
        mileage: current.mileage / 1000 + "km",
        speed: current.speed / 10 + "km/h",
        mdtstatus: current.mdtstatus,
        alarmstatus: current.alarmstatus,
        point: {
          lon: current.lon,
          lat: current.lat
        },
        holdname: current.holdname,
        workMode: current.workMode,
        posTypeStr: current.posTypeStr,
        disturbStatus: current.disturbStatus,
        gradeClass: current.gradeName + current.className,
        alarmTypes: current.alarmList ? current.alarmList.map(p => p.alarmName) : []
      };
    });
    // 获取地图
    function getMap() {
      return [refData.refMap.getMap(), refData.refMap];
    }
    // 获取marker
    const getData = async (type, holdid) => {
      console.log("getStudentsPosBySchool" + holdid);
      const childFlag = 1;
      const {
        data,
        code,
        msg
      } = await httpApi.getStudentsPosBySchool({
        holdid,
        childFlag,
        type
      });
      refData.points = data === null || data === void 0 ? void 0 : data.posList;
      code == 0 && (data === null || data === void 0 ? void 0 : data.posList) && handleOpen(data === null || data === void 0 ? void 0 : data.posList);
    };
    // 信息框
    const handleInfoWindow = () => {
      nextTick(() => {
        const [map, refMap] = getMap();
        if (map) {
          refData.infoWindow = map.InfoWindow({
            width: 360,
            height: 360
          }, refData.refInfo);
        }
      });
    };
    function setCurrentStudentId(currentCardId) {
      const [map, refMap] = getMap();
      refData.currentCardId = "";
      refData.currentCardId = currentCardId;
      let marker = mapClustererMarkers.find(marker => marker.info.objectid === currentCardId);
      if (marker) {
        refData.active = marker.info;
        // 标记点已经打开, 则关闭
        if (refMap.isOpenWindow()) {
          return closeInfoWindow();
        }
        openInfoWindow();
      }
    }
    // 设置地图中心点
    const panToPoint = async () => {
      const [map, refMap] = getMap();
      map && map.instance.setCenter(map.setLngLat([116.404412, 39.914714]));
    };
    // 开启聚合
    const handleOpen = params => {
      nextTick(() => {
        const [map, refMap] = getMap();
        refMap.clearClusterer();
        if (params.length === 0) {
          refMap.addClusterer([]);
          return;
        }
        ;
        // const markers = [];
        mapClustererMarkers = [];
        let num = 0;
        const deDuplication = [];
        params.map((item, index) => {
          //if(item.lat != 0 && item.lon != 0 ){
          num++;
          const {
            lat,
            lon
          } = bdEncrypt([item.lon, item.lat]);
          const marker = {
            point: [lon, lat],
            acc: item,
            info: item
          };
          mapClustererMarkers.push(marker);
          //}
        });
        refMap.addClusterer(mapClustererMarkers);
      });
    };
    return {
      ...toRefs(refData),
      handleMarkerClick,
      setCurrentStudentId,
      getData,
      handleInfoWindow,
      handleOpen,
      infoContent,
      panToPoint
    };
  }
});