import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, withCtx as _withCtx } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_InfoContent = _resolveComponent("InfoContent");
  const _component_BSJMap = _resolveComponent("BSJMap");
  return _openBlock(), _createBlock(_component_BSJMap, {
    ref: "refMap",
    zoom: 6,
    isSatellite: true,
    class: _normalizeClass(_ctx.isCustomizeStyle ? 'map_box' : ''),
    infoWidth: 340,
    infoHeight: 280,
    isCustomizeStyle: _ctx.isCustomizeStyle,
    onHandleMarkerClick: _ctx.handleMarkerClick
  }, {
    window: _withCtx(() => [_ctx.infoContent ? (_openBlock(), _createBlock(_component_InfoContent, {
      key: 0,
      isEmbed: _ctx.isEmbed,
      info: _ctx.infoContent,
      "onAction:gj": _ctx.onGJClick,
      "onAction:detail": _ctx.onDetailClick,
      "onAction:dm": _ctx.onDMClick,
      "onAction:cmd": _ctx.onCmdDownClick,
      "onAction:onPanorama": _ctx.onPanoramaClick
    }, null, 8, ["isEmbed", "info", "onAction:gj", "onAction:detail", "onAction:dm", "onAction:cmd", "onAction:onPanorama"])) : _createCommentVNode("", true)]),
    _: 1
  }, 8, ["class", "isCustomizeStyle", "onHandleMarkerClick"]);
}