import GPS from '@/assets/js/GPS';

type Lat = number;
type Lon = number;

export interface LngLat {
  lat: Lat;
  lng: Lon;
}

export interface LonLat {
  lat: Lat;
  lon: Lon;
}

type LonLatTuple = [Lon, Lat];

type InLonLat = LonLat | LngLat | [number, number];

export function isLngLat(point: InLonLat): point is LngLat {
  return 'lng' in point;
}

export function isLonLat(point: InLonLat): point is LonLat {
  return 'lon' in point;
}

export function toLonLat(point: InLonLat): LonLat {
  if (!point) return null;
  if (isLonLat(point)) return point;
  if (isLngLat(point)) {
    return { lon: point.lng, lat: point.lat };
  }
  return { lon: point[0], lat: point[1] };
}

/** BD09 to WGS84 */
export function bdDecrypt(point: InLonLat): LonLat {
  let inPoint = toLonLat(point);
  const result = GPS.bd_decrypt(inPoint.lat, inPoint.lon);
  return GPS.deltaOut(result.lat, result.lon);
}

/** WGS84 to BD09 */
export function bdEncrypt(point: InLonLat): LonLat {
  let inPoint = toLonLat(point);
  const result = GPS.delta(inPoint.lat, inPoint.lon);
  return GPS.bd_encrypt(result.lat, result.lon);
}

// 判断两个位置点是否相同
export function isSomePoint(point1: InLonLat, point2: InLonLat) {
  const inPoint1 = toLonLat(point1);
  const inPoint2 = toLonLat(point2);

  return (
    inPoint1 &&
    inPoint2 &&
    inPoint1.lon === inPoint2.lon &&
    inPoint1.lat === inPoint2.lat
  );
}

export default {
  bdDecrypt,
  bdEncrypt
};